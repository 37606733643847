import {
  Alert,
  Autocomplete,
  Box,
  Card,
  Container,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { Router, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, ClipboardEvent } from "react";
import { Logo } from "../components/logo";
import { LoadingButton } from "@mui/lab";
import Link from "@mui/material/Link";
import { useAuth } from "../hooks/use-auth";
import { AuthContext } from "../contexts/AuthContext";
import { apiService } from "../services/api.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import countries from "../utils/countries-list";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://swiftbill.co/">
        SwiftBill
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

const TrackMobile = () => {
  const params: any = useParams();
  const queueId: string = params?.queue_id;
  const { isLoggedIn, defaultTemplate } = React.useContext(AuthContext);
  const { login } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState<any>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  let navigate = useNavigate();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const countryValidation = (value: any) => {
    return (
      !!value && countries.some((country) => country.phone === value.phone) // Check if the selected country is a valid option
    );
  };

  const formik = useFormik({
    initialValues: {
      countryCode: countries.find((country) => country.phone === "US") || null,
      phoneNumber: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(phoneRegExp, "Valid Mobile No. is required")
        .max(13)
        .min(6)
        .required("Mobile No. is required"),
      countryCode: Yup.mixed().test(
        "isValidCountry",
        "Country code is required",
        countryValidation
      ),
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        setLoading(true);
        formik.resetForm();
        setLoading(false);
      } catch (err) {
        formik.resetForm();
        // loginaction({success:false, data:formik.values})
        setLoading(false);

        helpers.setStatus({ success: false });
        // helpers.setErrors({ submit: err.params });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(
      "countryCode",
      countries.find((country) => country.phone === "+1") || null
    );
  }, []);

  const fetchData = async () => {
    try {
      const tokenData = await apiService.post("/qms/get_token_detail", {
        queueId: queueId,
        mobileNo: formik.values.countryCode?.phone + formik.values.phoneNumber,
      });
      console.log(tokenData);
      navigate("/track/status", { replace: true, state: tokenData });
    } catch (error: any) {
      console.log("error", error);
      navigate("/track/status", { replace: true, state: {} });
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(../../static/images/login-bg.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{ p: 5, mt: 10 }} variant="elevation">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Logo></Logo>
            <Typography variant="h5" component="h1" gutterBottom>
              Enter Mobile No.
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="h1"
              gutterBottom
            >
              Enter Mobile No. entered while getting the token.
            </Typography>
            {isInvalid ? (
              <Alert style={{ width: "100%" }} severity="error">
                {errorMessage}
              </Alert>
            ) : (
              <></>
            )}

            <Box
              sx={{
                columnGap: "26px",
                my: 5,
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Stack flexDirection={"row"} sx={{ width: "100%" }}>
                <Autocomplete
                  // id="countryCode"
                  sx={{
                    width: "50%",
                    marginRight: 1,
                    marginTop: 2,
                  }}
                  options={countries}
                  autoHighlight
                  onChange={(e: any, value: any) =>
                    formik.setFieldValue("countryCode", {
                      phone: value?.phone || "",
                    })
                  }
                  // defaultValue={countries.find(
                  //   (c) => c.phone == userData.countryCode
                  // )}
                  getOptionLabel={(option) => option.phone}
                  value={formik.values.countryCode}
                  onBlur={formik.handleBlur}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
                      {...props}
                    >
                      <Typography variant="body2">{option.phone}</Typography>
                      <Typography sx={{ pl: 1 }} variant="body2">
                        {" "}
                        ({option.code})
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        formik.touched.countryCode && formik.errors.countryCode
                      )}
                      helperText={
                        formik.touched.countryCode && formik.errors.countryCode
                      }
                      name="countryCode"
                      label={"Country Code"}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />

                <TextField
                  sx={{
                    width: "100%",
                  }}
                  autoFocus
                  error={Boolean(
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  )}
                  fullWidth
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  placeholder="(xxx)-xxx-xxxx"
                  label={"Mobile No."}
                  margin="normal"
                  name="phoneNumber"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="tel"
                  value={formik.values.phoneNumber}
                />
              </Stack>
            </Box>

            <LoadingButton
              disabled={!formik.isValid}
              sx={{ mb: 4 }}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              onClick={() => fetchData()}
            >
              Track Status
            </LoadingButton>
            <Copyright />
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default TrackMobile;
