import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Image, { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Box, fontSize } from '@mui/system';
import { scaleSize } from '../utils';


type Variant = 'light' | 'primary';

export const NowServingAndOrderReady = (props:any) => {
    const { tokenNumber, heading, } = props;

    const getSizefromToken=(tokenNumber:number)=>{
        if(!tokenNumber){
            return 80
        }
            if(tokenNumber <=99)
            {
                return 70
            }
            else if(tokenNumber <= 999){
                return 60
            }
            else{
                return 52
            }
    }

   const tokenSize =  useMemo(()=>{
    if(!tokenNumber){
        return 80
    }
        if(tokenNumber <=99)
        {
            return 80
        }
        else if(tokenNumber <= 999){
            return 60
        }
        else{
            return 52
        }
    },[tokenNumber])


    console.log('tokenSize', tokenSize);
    
  return (
    <Box>

    <Typography>

        <Box sx={{ width: '100%', height: '12vh', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{cursor:'pointer'}} onClick={()=>{props.changeScreen()}}>
            <Typography fontSize={scaleSize(16)} fontWeight={'bold'} color={'white'}>{heading}</Typography>
            </div>
        </Box>

    </Typography>

​

    <Typography>

        <Box sx={{ flex: 1, width: '100%', height: '70vh', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

         
            <Typography fontSize={scaleSize(tokenSize)} fontWeight={'bold'} color={'white'}>{tokenNumber}</Typography>

        </Box>

    </Typography>

​

</Box>
  );

  
};

