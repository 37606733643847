
import { apiService } from '../services/api.service';

class AuthApi {
  async login({
    deviceCode
  }: {
    deviceCode: string;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const user = apiService.post('/auth/device', {
          deviceCode
        });

        resolve(user);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async reAuth(query?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const resp = apiService.get(
          `/auth/reAuthorize${query ? `?q=${query}` : ''}`
        );
        resolve(resp);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
       
        resolve(apiService.get('/auth/device/logout'));
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  me(data: { [key: string]: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        resolve(data);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  
}
export const authApi = new AuthApi();
