import { Box, Card, Container, Grid, TextField, Typography, IconButton, Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { NowServingAndOrderReady } from '../components/nowServing-orderReady';
import { scaleSize } from '../utils';
import { Socket, io } from "socket.io-client";
import parsedConfigs from '../configs';
import { InQueueComponent } from '../components/inqueue';
import { SideImage } from '../components/sideImage';
import { AuthContext, AuthProvider } from '../contexts/AuthContext';
import { PauseOperationImage } from '../components/pauseOperationImage';
import FullscreenRoundedIcon from '@mui/icons-material/FullscreenRounded';
import FullscreenExitRoundedIcon from '@mui/icons-material/FullscreenExitRounded';
import { LoadingButton } from '@mui/lab';

export const Token = () => {
  const [queueList, setQueueList] = useState([])
  const [servingList, setServingList] = useState([])
  const [deliveryList, setDeliveryList] = useState([])

  const [servingToken, setServingToken] = useState()

  const [orderReadyToken, setOrderReadyToken] = useState()

  const [pauseOperation, setPauseOperation] = useState(false)
  const [showTopBar, setShowTopBar] = useState(true);
  const [loading, setLoading] = useState(false);

  const params: any = useParams();

  const { isLoggedIn,logout, pauseOperationUrl, pauseOperationText, defaultTemplate, defaultPauseOperationImage, defaultPauseOperationText } = React.useContext(AuthContext);



  const option: number = parseInt(params?.option) ?? 1

  // console.log('option', params);

  // console.log('option', option);

  // console.log('pauseOperationText',pauseOperationText);
  
  // console.log('defaultPauseOperationImage',defaultPauseOperationImage);
  // console.log('defaultPauseOperationText',defaultPauseOperationText);
  


  let socket: any = null

  useEffect(() => {

    socket = io(parsedConfigs.socketUrl,{
      withCredentials: true
    });

    // socket = io(parsedConfigs.socketUrl);

    socket.on('connect', function (data:any) {

      console.log('== socket connected ==' + socket.id)
      // console.log('data   on connect --- ', JSON.stringify(data))

      // setQueueList(data?.InQueue)

      // setServingList(data?.Serving)

      // setDeliveryList(data?.Completed)
      // setServingToken(data?.nowServing)
      // setOrderReadyToken(data?.orderReady)

    });

    socket.on('disconnect', () => {

      console.log('== socket disconnected ==')

    });

    socket.on("update", (data: any) => {

      console.log('data   --- ', JSON.stringify(data))

      setQueueList(data?.InQueue)

      setServingList(data?.Serving)

      setDeliveryList(data?.Completed)
      setServingToken(data?.nowServing)
      setOrderReadyToken(data?.orderReady)
      setPauseOperation(data?.pauseOperation)

    })

    return () => {
      socket?.disconnect();
    };

  }, [])

  const imageUrl = useMemo(()=>{

    if(defaultTemplate){
      if(defaultTemplate.templateImage != '' || defaultTemplate.templateImage != null)
      {
        return defaultTemplate.templateImage
      }
      else{
        return 'https://swiftbill-dev.s3.ap-south-1.amazonaws.com/swiftbill-logo.png'
      }
    }
  },[defaultTemplate])

  let navigate = useNavigate();
  const changeUI = () => {

    // console.log('Here');

    let screenNumber = option
    if (screenNumber < 8) {
      screenNumber += 1
    }
    else {
      screenNumber = 1
    }
    navigate(`/token/${screenNumber}`, { replace: true });

  }

  const queueOption = [1, 3,6, ]
  const servingOption = [1, 2, 3, 4,6,7]
  const orderReadyOption = [1, 2,5,8]
  const sideImageOption = [6,7,8]


  const imagesList:any = {6:'m1.jpg',7:'m1.jpg',8:'m2.jpeg'}

  const handleMouseMove = (e: any) => { 
    if (e.clientY <= 65) {
        setShowTopBar(true);
    } else {
        setShowTopBar(false);
    }
}

const toggleFullscreen = () => {
    // https://www.aha.io/engineering/articles/using-the-fullscreen-api-with-react
    if (Boolean(document.fullscreenElement)) {
        document.exitFullscreen();
    } else {
        document.body.requestFullscreen()
    }
}
const logoutFunc=async()=>{

  setLoading(true)
  try{

    await logout()
  setLoading(false)

  }
  catch(err){
  setLoading(false)

    console.log('error in logout');
    
  }
}

  if(pauseOperation)
  {
      return (
        <Grid container sx={{ flexDirection: 'row',flex:1, justifyContent:'center', alignItems:'center', backgroundColor: 'black', height: "100vh",}} onMouseMove={(e)=> handleMouseMove(e)}>
          <Box 
            sx={{ backgroundColor: '#00000090', 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                height: 65, 
                width: '100vw', 
                padding: '15px',
                display: showTopBar ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                spacing: '50px'
            }}
        >
            {/* <IconButton color='error' size='large' aria-label="Fullscreen" onClick={ () => toggleFullscreen() }>
                {
                    Boolean(document.fullscreenElement)
                    ?
                    <FullscreenExitRoundedIcon fontSize="inherit" />
                    :
                    <FullscreenRoundedIcon fontSize="inherit" />
                }
            </IconButton> */}
            <LoadingButton variant="outlined" color='info' onClick={()=>logoutFunc()} style={{ marginLeft: '20px' }}>Logout</LoadingButton>
        </Box>
              <PauseOperationImage src= {pauseOperationUrl} defaultPauseOperationImage={defaultPauseOperationImage} defaultPauseOperationText={defaultPauseOperationText} />
           {
          
            <Typography  fontSize={scaleSize(10)} sx={{color:'white',
            backgroundColor:'#000000c0',
            width:'100%', display:'inline-flex',position:'absolute',
            bottom:0,
            paddingY:2,
            paddingX:5,
            justifyContent:'center',
            textAlign:'center',
          }} fontWeight={'bold'} color={'white'} >{pauseOperationText || defaultPauseOperationText}</Typography>
       
        }
         
        </Grid>
      )
  }
  else{
    return (
      <Grid container sx={{ flexDirection: 'row' }} onMouseMove={(e)=> handleMouseMove(e)}>
        <Box 
            sx={{ backgroundColor: '#00000090', 
                position: 'absolute', 
                top: 0, 
                left: 0, 
                height: 65, 
                width: '100vw', 
                padding: '15px',
                display: showTopBar ? 'flex' : 'none',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                spacing: '50px'
            }}
        >
            {/* <IconButton color='error' size='large' aria-label="Fullscreen" onClick={ () => toggleFullscreen() }>
                {
                    Boolean(document.fullscreenElement)
                    ?
                    <FullscreenExitRoundedIcon fontSize="inherit" />
                    :
                    <FullscreenRoundedIcon fontSize="inherit" />
                }
            </IconButton> */}
            <LoadingButton  variant="outlined" color='info'onClick={()=>logoutFunc()} style={{ marginLeft: '20px' }}>Logout</LoadingButton>
        </Box>
        {
          queueOption.some((key: any) => key == option)
            ?
            <Grid item width={'20%'} sx={{ backgroundColor: 'black', height: '100vh', border: 1, borderColor: 'white', overflow: 'hidden' }}>
              <InQueueComponent queueList={queueList} changeScreen={() => changeUI()} />
            </Grid>
            :
            <></>
        }

        {
          servingOption.some((key: any) => key == option)
            ?
            <Grid item width={'40%'} sx={{ flex: 1, backgroundColor: 'black', height: "100vh", border: 1, borderColor: 'white', overflow: 'hidden' }}>
              <NowServingAndOrderReady
                heading={'Now Serving'}
                tokenNumber={servingToken == null ? '--' : servingToken}
                changeScreen={() => changeUI()}
              />
            </Grid>
            : <></>
        }
        {
          orderReadyOption.some((key: any) => key == option)
            ?
            <Grid item width={'40%'} sx={{ flex: 1, backgroundColor: 'black', height: "100vh", border: 1, borderColor: 'white', overflow: 'hidden' }}>
              <NowServingAndOrderReady
                heading={'Order Ready'}
                tokenNumber={orderReadyToken == null ? '--' : orderReadyToken}
                changeScreen={() => changeUI()}
              />
            </Grid> :
            <></>
        }
        
        {
          sideImageOption.some((key: any) => key == option)
            ?
            <Grid item width={'40%'} sx={{ flex: 1, backgroundColor: 'black', height: "100vh", border: 1, borderColor: 'white', overflow: 'hidden' }}>
              <SideImage src= {imageUrl}/>
            </Grid> :
            <></>
        }
      </Grid>

    )
}


}



