import * as React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Test } from "./pages/test";
import { Button } from "@mui/material";
import {
  AuthConsumer,
  AuthContext,
  AuthProvider,
} from "./contexts/AuthContext";
import Auth from "./pages/auth";
import { Token } from "./pages/token";
import { SplashScreen } from "./pages/splash-screen";
import NotFound from "./pages/404";
import Tracker from "./pages/track";
import TrackMobile from "./pages/track-mobile";

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isLoggedIn, logout } = React.useContext(AuthContext);

  console.log("isLoggedIn", isLoggedIn);

  //  const test = localStorage.getItem("@token");
  let location = useLocation();
  if (!isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default function App() {
  const { isLoading } = React.useContext(AuthContext);
  console.log("isLoading", isLoading);

  if (isLoading) {
    return <Test />;
  } else {
    return (
      <AuthProvider>
        <BrowserRouter>
          <AuthConsumer>
            {(auth) =>
              !auth.isInitialized ? (
                <SplashScreen />
              ) : (
                <Routes>
                  {/* <Route index element={<Landing />} /> */}
                  <Route path="/" element={<Auth />} />
                  {/* <Route path="/logout" element={<LogoutPage />}  /> */}
                  <Route
                    path="/token/:option"
                    element={
                      <RequireAuth>
                        <Token />
                      </RequireAuth>
                    }
                  />

                  {/* <Route
                            path="/connect"
                            element={
                              <RequireAuth>
            
                                <Test />
                              </RequireAuth>
                              
                            }
                    /> */}
                  <Route
                    path="/track-mobile/:queue_id"
                    element={<TrackMobile />}
                  />
                  <Route path="/track/:track_id" element={<Tracker />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              )
            }
          </AuthConsumer>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

// function LoginPage() {

//   return (
//     <div>
//       <p>You must log in to view the page at </p>

//       <form>
//         <label>
//           Username: <input name="username" type="text" />
//         </label>{" "}

//         <Button onClick={()=>console.log('asd')
//         }>Login</Button>
//         <button type="submit">Login</button>
//       </form>
//     </div>
//   );
// }

// function LogoutPage () {

//   const { isLoggedIn, logout } = React.useContext(AuthContext);
//   const [loggedIn, setIsLoggedIn] = React.useState(isLoggedIn)

//   const logoutFunc =async()=>{
//     const data = await logout()

//   }

//   React.useEffect(()=>{
//     logoutFunc()
//   },[])

//   if(isLoggedIn)
//   {
//     return (<>Please wait...</>)
//   }
//   else{
//     return (<Navigate to="/"  replace />)
//   }

// }
