import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Image, { Divider, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { Box, fontSize } from '@mui/system';
import { scaleSize } from '../utils';


type Variant = 'light' | 'primary';

export const InQueueComponent = (props: any) => {
    const { queueList } = props;



    return (
        <Box >
            <Typography >

                <Box sx={{ width: '100%', height: '12vh', backgroundColor: '#f87171', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                    <div style={{ cursor: 'pointer' }} onClick={() => { props.changeScreen() }}>
                        <Typography fontSize={scaleSize(16)} fontWeight={'bold'} color={'white'} >In Queue</Typography>
                    </div>

                </Box>

            </Typography>

            {queueList.length == 0 ?

                <Box sx={{ width: '100%', height: '88vh', backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Typography fontSize={200} fontWeight={'bold'} color={'white'}>--</Typography>

                </Box>

                :

                <Box sx={{ width: '100%', height: '88vh', backgroundColor: 'black', justifyContent: 'center', alignItems: 'center', overflowY: "scroll" }}>

                    {queueList.slice(0, 5).map((i: any) => {

                        return (
                            <>

                                <Box sx={{ width: '100%', marginTop: 1, backgroundColor: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                    <Typography fontSize={scaleSize(30)} fontWeight={'bold'} color={'white'}>{i?.tokenNo}</Typography>

                                </Box>
                                <Divider />

                            </>

                        )

                    })}

                </Box>

            }



        </Box>
    );


};

