import { Card, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import { useAuth } from '../hooks/use-auth';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { authApi } from '../api/auth-api';
import config from '../configs';



  
export const Test =()=> {
    const { logout, reauth } = useAuth();
    let navigate = useNavigate();
    const [resp,setResp] = React.useState('')
    const [loading, setLoading] = React.useState(false);

    const logoutPortal=async()=>{
        setLoading(true)
        try{
        
       
            
        const data:any = await authApi.logout();
        console.log('hereee', data);
        // setResp(JSON.stringify(data))
        setLoading(false)
        localStorage.removeItem('token')
        // navigate('/')
        navigate('/', { replace: true });

        }
        catch(err:any){
            console.log('something went wrong');
            // setResp(JSON.stringify(err))
        setLoading(false)
            
        }
    }

    useEffect(()=>{


    },[])
    

    const testfunc=async()=>{
        const token:string = localStorage.getItem('token')??''

        console.log('token', token);
       await fetch(`${config.apiUrl}/auth/device/logout`, {
            method: 'GET',
            credentials: 'include'
          })
            .then((response) => response.json())
            .then((json) => {
              console.log('Gotcha');
              setResp('gotcha')
              localStorage.removeItem('token')
              navigate('/', { replace: true });
            }).catch((err) => {
              console.log(err);
          });
        
    }
  return (
    <Container maxWidth="sm">
    <Card sx={{p:5, mt:10,}} variant='elevation'>
            <Typography >{resp}</Typography>

            <Button variant='contained' onClick={()=>testfunc()}>Test</Button>

            <LoadingButton
        // disabled={formik.isSubmitting}
                    sx={{mb:4,}}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    onClick={()=>logoutPortal()
        }
      >
        Connect
      </LoadingButton>
  
    </Card>

    
  </Container>
  )
}



