import { useEffect } from "react";
import * as React from "react";
import { apiService } from "../services/api.service";

import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  CircularProgress,
  Stack,
} from "@mui/material";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fontSize, padding, positions } from "@mui/system";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

export const Tracker = () => {
  const params: any = useParams();
  const trackID: string = params?.track_id;
  const { state } = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [tokenNo, setTokenNo] = React.useState(null);
  const [tokenState, setTokenState] = React.useState(null);
  const [lineItems, setLineItems] = React.useState<any>([]);

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchData = async () => {
    setLoading(true);
    try {
      const tokenData = await apiService.post("/qms/get_token_detail", {
        tokenURL: trackID,
      });
      setTokenNo(tokenData.tokenNo);
      setTokenState(tokenData.tokenState);
      if (Array.isArray(tokenData.order)) {
        setLineItems(tokenData.order);
      }
      setLoading(false);
    } catch (error: any) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (trackID === "status") {
      const { tokenNo, tokenState, order } = state;
      setTokenNo(tokenNo);
      setTokenState(tokenState);
      if (Array.isArray(order)) {
        setLineItems(order);
      }
    } else {
      fetchData();
    }
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          pt: 5,
          backgroundColor: "background.paper",
          display: "flex",
          flexGrow: 1,
          // height: "100vh",
          height: "auto",
        }}
      >
        <Container maxWidth="lg">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography
                align="center"
                variant={mobileDevice ? "h5" : "h3"}
                paddingBottom={3}
                color={"#022650"}
              >
                {tokenState ? "Token Number" : ""}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span
                  style={
                    mobileDevice
                      ? {
                          fontSize: "8rem",
                          fontWeight: 400,
                          borderRadius: 20,
                          backgroundColor: "#022650",
                          color: "#ffff",
                          padding: "0 20px",
                          lineHeight: "normal",
                        }
                      : {
                          fontSize: "15rem",
                          fontWeight: 800,
                          borderRadius: 20,
                          backgroundColor: "#022650",
                          color: "#ffff",
                          padding: "0 20px",
                          lineHeight: "normal",
                        }
                  }
                >
                  {tokenNo}
                </span>
              </Box>
              <Typography
                align="center"
                variant={mobileDevice ? "h4" : "h1"}
                paddingTop={5}
                color={"#022650"}
              >
                {tokenState ? `Status: ${tokenState}` : "Record Not Found"}
              </Typography>

              {lineItems.length ? (
                <>
                  <Divider sx={{ pt: 5 }} variant="middle" flexItem />
                  <Typography
                    align="center"
                    variant={mobileDevice ? "h5" : "h1"}
                    color={"#022650"}
                  >
                    Order Details
                  </Typography>
                  <Divider variant="middle" flexItem />
                  <Divider sx={{ pt: 1 }} variant="middle" flexItem />
                  <List
                    sx={{
                      margin: "0 auto",
                      width: mobileDevice ? "100%" : "90%",
                      // maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    {lineItems.map((obj: any) => (
                      <Box sx={{ pb: 2 }}>
                        <Stack
                          flex={1}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          sx={{ mr: 1 }}
                        >
                          <Typography color={"#022650"} fontWeight={700}>
                            {obj.itemName}
                          </Typography>

                          <Typography color={"#022650"} fontWeight={700}>
                            ${parseFloat(obj.amount).toFixed(2)}
                          </Typography>
                        </Stack>

                        {obj.otherDetails.map((otherObj: any) => (
                          <Typography color={"#022650"} fontWeight={700}>
                            {otherObj.label}
                          </Typography>
                        ))}
                      </Box>
                    ))}
                  </List>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            // style={{ position: "absolute", bottom: 10, right: 10, left: 10 }}
            sx={{ pt: 5, pb: 2 }}
          >
            {"Copyright © "}
            <Link color="inherit" href="https://swiftbill.co/">
              SwiftBill
            </Link>{" "}
            {new Date().getFullYear()}.
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Tracker;
