import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import React from 'react';

type Variant = 'light' | 'primary';

interface SideImageProps {
  src?: string;
  defaultPauseOperationText:string;
  defaultPauseOperationImage: string;
  variant?: Variant;
}

export const PauseOperationImage = styled((props:SideImageProps) => {
  const { src, defaultPauseOperationImage, defaultPauseOperationText,...other  } = props;

  return (
    <div
    style={{overflow:'hidden', width:'100%', height:'100%'}}
    >
    <img  src={src || defaultPauseOperationImage} alt="Image"   height="100%" width='100%'  style={{objectFit:'contain'}} {...other}/>
  </div>
  );

  
})``;

PauseOperationImage.defaultProps = {
  variant: 'primary',
};

PauseOperationImage.propTypes = {
  variant: PropTypes.oneOf<Variant>(['light', 'primary']),
};
