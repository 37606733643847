

interface Config {
  env: string;
  isBrowser: boolean;
  isServer: boolean;
  defaultTimeZone: string;
  apiUrl: string;
  socketUrl: string;
  authApiUrl: string;
  expenseUploadApiUrl: string;
  calendlyConfig: { [key: string]: any };
}

const config: { [name: string]: any } = {
  all: {
    env: process.env.REACT_APP_ENV || 'local',
    isBrowser: typeof window !== 'undefined',
    isServer: typeof window === 'undefined',
    defaultTimeZone: 'Asia/Hong_Kong',
  },
};

const parsedConfigs: Config = {
  ...config.all,
  apiUrl:   process.env.REACT_APP_ENV == "production" ? `https://api-pdn.swiftbill.co/api/v1`  :`https://dev.api.swiftbill.co/api/v1`,
  socketUrl:  process.env.REACT_APP_ENV == "production" ?`https://api-pdn.swiftbill.co/qms`: `https://dev.api.swiftbill.co/api/v1/qms` ,
  calendlyConfig: {
    url: 'https://calendly.com/kenny-binery/45-minute-onboarding-call-with-kenny',
    text: 'Schedule time with me',
    color: '#00a2ff',
    textColor: '#ffffff',
    branding: true,
  },
};

export default parsedConfigs;
