import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Image, { CardMedia } from '@mui/material';
import React from 'react';
// import Image from 'next/image';

type Variant = 'light' | 'primary';

interface SideImageProps {
  src?: string;
  variant?: Variant;
}

export const SideImage = styled((props:SideImageProps) => {
  const { src, ...other } = props;

  return (
    <div
    style={{overflow:'hidden', width:'100%', height:'100%'}}
    >
    <img  src={src || 'https://swiftbill-dev.s3.ap-south-1.amazonaws.com/Default/sideImageDisplay.jpg'} alt="Image"   height="100%"  style={{objectFit:'cover'}} {...other}/>
  </div>
    
  );

  
})``;

SideImage.defaultProps = {
  variant: 'primary',
};

SideImage.propTypes = {
  variant: PropTypes.oneOf<Variant>(['light', 'primary']),
};
